import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import Sizing from "@/components/general/Sizing"
import Spacing from "@/components/general/Spacing"
import SEO from "@/components/seo"
import styles from "@/styles/about.module.scss"
import Footer from "@/components/general/Footer"
import logo from "@/images/logo-sq.png"

const AboutPage = () => {
    return (
        <>
            <SEO
                title="About Free Markets Destroy | A Project of Washington Policy Center"
                description="Free Markets Destroy celebrates the power of free markets to tackle humanity's biggest challenges. Be a part of the solution by harnessing the power of markets to improve everything."
            />

            <div className={styles.main}>
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 22,
                                float: "left",
                                marginLeft: 1,
                                marginTop: 2,
                                marginBottom: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 14,
                                float: "left",
                                marginLeft: 5,
                                marginTop: 2,
                                marginBottom: 4,
                            },
                        },
                    ]}
                >
                    <h1 className={styles.title + " h2"}>About</h1>
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {},
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 10,
                                    float: "left",
                                },
                            },
                        ]}
                    >
                        <img
                            style={{
                                width: "100%",
                                transform: "translate3d(0 , 0,1px)",
                            }}
                            src={logo}
                            alt=""
                        />
                        <p className="copy">
                            A Project of <b>Washington Policy Center</b>
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 14,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            About Free Markets Destroy
                        </span>
                        <p className="copy">
                            Free Markets Destroy celebrates the power of free
                            markets to tackle humanity's most daunting
                            challenges. The world isn't perfect, but it's
                            getting better every day thanks to entrepreneurs who
                            work tirelessly to deliver life-changing
                            innovations. Be a part of the solution by harnessing
                            the power of markets to improve everything.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 14,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            About Washington Policy Center
                        </span>
                        <p className="copy">
                            <a
                                href={"https://www.washingtonpolicy.org/"}
                                target="_blank"
                                style={{
                                    backgroundColor: "#BCE0DB",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Washington Policy Center
                            </a>{" "}
                            is one of the nation’s leading state-based,
                            free-market public policy research organizations.
                            Based in Seattle with offices located throughout
                            Washington state, Washington Policy Center is
                            non-partisan and non-profit, and publishes studies,
                            sponsors events and educates citizens on the vital
                            public policy issues. Ideas supported by sound
                            research and promoted through publications, events
                            and the media, over time, create an environment in
                            which policymakers and citizens make sound public
                            policy decisions. Washington Policy Center focuses
                            on eight key research areas: Agriculture, Education,
                            Environment, Government Reform, Health Care, Small
                            Business, Transportation and Worker Rights. In
                            addition, WPC’s Young Professionals program focuses
                            on educating, engaging and empowering the next
                            generation of free-market leaders in Washington
                            state.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 14,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            Creative, Copywriting & Strategy
                        </span>
                        <p className="copy">
                            <a
                                href={"https://eo.foundation/"}
                                target="_blank"
                                style={{
                                    backgroundColor: "#D7C2DA",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Emergent Order
                            </a>{" "}
                            is a creative studio that transforms big ideas into
                            breakthrough opportunities. We leverage media
                            trends, technology, and close collaboration with our
                            partners to create memorable stories on any
                            platform. We don’t work on retainer. We don’t have a
                            B team. And we don’t offer templated, off-the-shelf
                            “solutions” for marketing or media. To solve our
                            partners’ problems we design and produce custom
                            strategies and compelling stories.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 6,
                                    float: "left",
                                    marginTop: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>
                            Design & Development
                        </span>
                        <p className="copy">
                            <a
                                href={"https://gladeye.com/"}
                                target="_blank"
                                style={{
                                    backgroundColor: "#EAAEA4",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Gladeye
                            </a>{" "}
                            is a digital innovations agency in Auckland, New
                            Zealand and New York.
                        </p>
                    </Sizing>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: { float: "left", marginTop: 2 },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 6,
                                    float: "left",
                                    marginTop: 2,
                                    marginLeft: 2,
                                },
                            },
                        ]}
                    >
                        <span className={"copy--lead"}>Illustrations</span>
                        <p className="copy">
                            <a
                                target="_blank"
                                href={"https://sugacyan.com/"}
                                style={{
                                    backgroundColor: "#A4CBEB",
                                    display: "inline-block",
                                    paddingLeft: "4px",
                                    paddingRight: "4px",
                                }}
                            >
                                Pablo Espinosa
                            </a>{" "}
                            is a Mexican illustrator living and working in
                            Tokyo, Japan.
                        </p>
                    </Sizing>
                </Sizing>

                <Spacing space={0}></Spacing>
            </div>
            <Footer></Footer>
        </>
    )
}

export default AboutPage
